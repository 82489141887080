import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { GrFacebookOption } from "react-icons/gr";
import logo from '../images/shopersbay_logo.webp';
import { useSelector, useDispatch } from 'react-redux';
import { setMenuTrue, setToggleTrue } from '../redux/slice/changeState'
import Contact from './Contact';
import { fetchNavigationData } from '../redux/slice/onLoad'

const Footer = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' });
    const toggleMenu = useSelector((state) => state.toggle)
    const { navigationList } = useSelector(state => state.master)
    const [filterFooter, setfilterfooter] = useState([])
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        if (navigationList != null) {

            const getData = navigationList.filter((ele) => ele.posts_type === 'policies');

            if (getData.length > 0) {
                setfilterfooter(getData)
            }

        }
    }, [navigationList])

    useEffect(() => {
        dispatch(fetchNavigationData())
    }, [])

    const handleNavigation = (path, hash) => {
        navigate(path, { replace: true }); // Navigate to the path
        setTimeout(() => {
            const targetElement = document.getElementById(hash); // Find the section by ID
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
            }
        }, 100); // Delay to ensure the component is loaded
    };

    return (
        <div className={`footer ${isMobile ? "pt-3" : "pt-5"} `}>
            <Container>
                <div>
                    <img src={logo} className='img-fluid' alt="logo"></img>
                </div>
                <Row className=''>
                    <Col lg={7} md={7} className='footer-logo-column pb-30'>

                        <p className='mb-2 fw-bold primary-text'>Contact Info:</p>
                        <Row className={`mb-7 contact-info ${isMobile ? '' : 'ml-50'}`}>
                            <div className='mb-2'>
                                <div className='d-flex'>
                                    <div className='primary-text'>Address : </div>
                                    <div className='mt-1'>
                                        <p className='footer-header'>
                                            H.No 4-49/2<br></br>
                                            Besides Anvaya Conventions<br></br>
                                            Financial District<br></br>
                                            Hyderabad, Telangana 500032<br></br>
                                            India</p>
                                    </div>
                                </div>

                            </div>
                            <div className='mb-2'>
                                <p className='footer-header'><span className='primary-text'>Phone:</span><NavLink to="tel:9885260004" target="_blank" className="text-decoration-none"> +91 9885260004</NavLink></p>
                            </div>
                            <div className='mb-2'>
                                <p className='footer-header'><span className='primary-text'>E-mail:</span><a className="text-decoration-none" href="mailto:info@irarealty.in" rel="noreferrer" target="_blank"> contact@shopersbay.com</a></p>
                            </div>
                            <div className=''>
                                <p className='footer-header'><span className='primary-text'>Hours:</span> Mon - Sat, 10:00 AM - 7:00 PM</p>
                            </div>
                        </Row>
                    </Col>
                    <Col lg={5} md={5} className={`footer-second-column ${isMobile ? "pt-4" : "pt-2"} `}>
                        <p className='primary-text fw-bold'>Quick Links:</p>
                        <Row className={`mb-7 contact-info ${isMobile ? '' : 'ml-50'}`}>
                            <div className='mb-2'>
                                <p className='footer-header '>
                                    <div
                                        className="footer-color-content"
                                        onClick={() => handleNavigation('/', 'pricing')}
                                    >
                                        Pricing
                                    </div>
                                    {/* <Link to="pricing" smooth={true} duration={1000} className='footer-color-content'> Pricing</Link> */}

                                </p>
                                <p className='footer-header '>
                                    <div
                                        className="footer-color-content"
                                        onClick={() => handleNavigation('/', 'faqs')}
                                    >
                                         FAQs
                                    </div>

                                </p>
                                <p className='footer-header footer-color-content' onClick={() => dispatch(setToggleTrue())}>Contact Us</p>
                            </div>
                        </Row>
                    </Col>
                </Row>
                {
                    isMobile ?
                        <Row className="fotter-contact footer-row">

                            {
                                filterFooter.length > 0 &&


                                filterFooter.map((ele, index) => {
                                    return (

                                        <Col lg={6} md={4} sm={6} xs={6} className="footer-text-contant footer-color-content" key={index}>
                                            <NavLink to={`/policies/${ele.list_key}`} className={"text-decoration-none footer-color-content"} >
                                                {ele.title}
                                            </NavLink>
                                        </Col>


                                    )
                                })
                            }

                        </Row>
                        :

                        <Row className='w-100 copyright d-flex justify-content-between footer-row'>
                            <Col lg={12} md={12} sm={12} xs={12} className="footer-types">
                                <div className="middle_paragraph">
                                    {
                                        filterFooter.length > 0 &&
                                        filterFooter.map((ele, index) => {
                                            return (
                                                <NavLink to={`/policies/${ele.list_key}`} className=" footer-color-content" key={index}>
                                                    <div className="footer-line footer-color-content">
                                                        {index !== 0 && "  |  "}
                                                    </div>
                                                    {"  "}
                                                    {ele.title}{"  "}
                                                </NavLink>
                                            )
                                        })
                                    }

                                </div>
                            </Col>
                        </Row>

                }






                <Row className='text-center'>
                    <div class="col-lg-12">

                        <p class="ourshop_com">Copyright © 2024 shopersbay.com. All rights reserved.</p>

                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
