import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ScrollToTop from './hooks/ScrollToTop';
import * as view from './view';
import * as ROUTES from './constant/routes';
import Apis from "./services/Apis";

function App() {
  const { getIp } = Apis();

  useEffect(() => {
    const data = localStorage.getItem('selectedIds');
    getIp()
    // const targetWindow = window.open('https://login.shopersbay.com/'); // Open the other website in a new window

    // // Wait for the target window to load before sending the message
    // if (targetWindow) {
    //   targetWindow.addEventListener('load', () => {
    //     targetWindow.postMessage({ data }, 'https://login.shopersbay.com/'); // Send the data
    //   });
    // }

    // // Clean up on component unmount
    // return () => {
    //   if (targetWindow) {
    //     targetWindow.close();
    //   }
    // };
  }, []); // Empty dependency array to run once after the component mounts

  return (
    <Router>
      <Suspense>
        <Routes>
          <Route
            path={ROUTES.Home}
            element={
              <ScrollToTop>
                <view.Home />
              </ScrollToTop>
            }
          />
          <Route
            path={ROUTES.Static}
            element={
              <ScrollToTop>
                <view.Static />
              </ScrollToTop>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
