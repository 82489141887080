import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    hastoggle: false,
    openMenu:false,
}

export const toggleSlice = createSlice({
    name: 'localState',
    initialState,
    reducers: {
        setToggleTrue: (state) => {
            state.hastoggle = true; // Update hastoggle to true
        },
        setToggleFalse: (state) => {
            state.hastoggle = false; // Update hastoggle to false
        },
        setMenuTrue: (state) => {
            state.openMenu = true; // Update openMenu to true
        },
        setMenuFalse: (state) => {
            state.openMenu = false; // Update openMenu to false
        },
        
    }
})

export const {setToggleTrue, setToggleFalse, setMenuTrue, setMenuFalse} = toggleSlice.actions

export default toggleSlice.reducer