import React from 'react'
import axios from 'axios';

const Apis = () => {
    axios.defaults.baseURL = "https://admin.shopersbay.com/";
    axios.defaults.headers.common['authentication'] = "21e7ac68af78a3be93bbf71958f216d9";

    const getIp = async () => {
        const response = await axios.get("asapi/setCountryCodeByIp");
        return response;
      }

    const getFaqs = async () => {
        const response = await axios.get("asapi/getFaqList");;
        return response;
    }

    const getModules = async (input_data) => {
        const response = await axios.post("asapi/getModuleList", input_data);
        return response;
    }

    const saveContactFormdata = async (input_data) => {
        const response = await axios.post("asapi/saveContact", input_data);
        return response;
    }

    const getImages = async () => {
        const response = await axios.get(`${window.location.origin}/images.json`);
        return response;
    }

    const getNavigationlist = async () => {
        const response = await axios.get("asapi/getStaticPageList");
        return response;
      }

    
    return {
        getIp: getIp,
        getFaqs: getFaqs,
        getModules: getModules,
        saveContactFormdata: saveContactFormdata,
        getImages: getImages,
        getNavigationlist: getNavigationlist,
    }
}

export default Apis
