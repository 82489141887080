import React, {useEffect, useState} from 'react'
import {Header, Footer} from '../components'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "react-bootstrap";

const Static = () => {
  const { slug } = useParams();
  const { navigationList } = useSelector(state => state.master)
  const [data, setdata] = useState([]);

  useEffect(() => {
    if ( navigationList && navigationList.length > 0) {
      setdata(navigationList.filter(ele => ele.list_key == slug))
    }
  }, [slug, navigationList])

  return (
    <>
    <Header />
    {
        data.length > 0 ?
        <>
          <div className="background-primary static-page">
            <div className="container conditionslinks ">
              <div className="space-30"></div>
              <h2 className="policies-titles">{data[0].title}</h2>
              <div className="space-30"></div>
              <div dangerouslySetInnerHTML={{ __html: data[0].body }} />
            </div>
          </div>
        </>
        : <div className='custom-height'></div>
      }
    <Footer />
    </>
  )
}

export default Static
