import React, { useState, useEffect } from 'react';
import logo from '../images/shopersbay_logo.webp';
import { useDispatch, useSelector } from 'react-redux';
import { setToggleTrue,setToggleFalse } from '../redux/slice/changeState';
import { fetchModuleData, addSelectedModule, countryCode, fetchIp } from '../redux/slice/onLoad';
import { useNavigate } from 'react-router-dom';
import Contact from './Contact';
import { Modal } from 'react-bootstrap';
import { IoCloseSharp } from "react-icons/io5";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = useSelector((state) => state.toggle)
  const { countryData } = useSelector((state) => state.master);
  const handleClose =()=>{
    dispatch(setToggleFalse())
  }
  const handleopen=()=>{
    dispatch(setToggleTrue())
    setIsMenuOpen(false);
  }

  useEffect(()=>{
    dispatch(fetchIp());
  },[])

  const handleDispatch = () => {
    setIsMenuOpen(false);
    dispatch(setToggleTrue());
  }
  const handleSignUp = () => {
    window.open("https://login.shopersbay.com/module?q=shopersbay", "_blank");
  };

  const handleSignIn = () => {
    window.open("https://login.shopersbay.com/shopersbay", "_blank");
  };

  const handleNavigation = (path, hash) => {
    setIsMenuOpen(false);
    navigate(path, { replace: true }); // Navigate to the path
    setTimeout(() => {
      const targetElement = document.getElementById(hash); // Find the section by ID
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll
      }
    }, 100); // Delay to ensure the component is loaded
  };

  // useEffect(() => {
  //     console.log("countryCode", countryData)
  //     dispatch(fetchIp());
  //     dispatch(fetchModuleData({ duration: "month", duration_type: "free_trial" }));
  //   }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          // Dispatch fetchIp and wait for the response
          const ipResponse = await dispatch(fetchIp()).unwrap();
    
          // Extract the country code from the IP response
          const countryCode = ipResponse.countryCode;
    
          // Dispatch fetchModuleData with the country code as part of the payload
          await dispatch(fetchModuleData({ 
            duration: "month", 
            duration_type: "free_trial", 
            countryCode : countryCode
          }));
          // console.log('Module data fetched with countryCode:', countryCode);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
    }, [dispatch]);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="logo" className="img-fluid" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo02"
            aria-controls="navbarTogglerDemo02"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
            id="navbarTogglerDemo02"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <button
                  className="btn nav-link"
                  onClick={() => handleNavigation('/', 'pricing')}
                >
                  Pricing
                </button>
              </li>
              <li
                className="nav-item"
                onClick={() => handleopen()}
              >
                Contact Us
              </li>
              <li className="nav-item">
                <button
                  className="btn nav-link"
                  onClick={() => handleNavigation('/', 'faqs')}
                >
                  FAQs
                </button>
              </li>
            </ul>
            <div className="btn-section">
              <button className="common-btn" onClick={handleSignIn}>
                Sign In
              </button>
              <button className="odd-btn" onClick={handleSignUp}>
                Try For Free
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* {
        toggleMenu.hastoggle &&
        <Contact />
      } */}
      <Modal
        show={toggleMenu.hastoggle}
        size="lg"
        centered
        // onHide={() => handleClose()}
       className='contact-form-modal'
     
      >
  <div className='text-end close-button p-3' onClick={handleClose}><IoCloseSharp /></div>
        <Modal.Body>
        <Contact />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Header;
